<template>
    <b-card class="top-row-section h-100" body-class="py-2">
        <b-row class="mb-2">
            <b-col md="6" lg="12" xl="6">
                <div class="d-flex mb-5">
                    <h5 class="heavy text-primary">
                        {{ t('surgeonDetails_MaxToric') }}:
                        <span class="ml-1 text-black grade"
                            >{{ doctor.maxToricAxisTolerance }}&deg;</span
                        >
                    </h5>
                </div>
                <div class="mb-5">
                    <h5 class="heavy text-primary">
                        {{ t('surgeonDetails_LocalSupport') }}
                    </h5>
                    <ul class="list-unstyled w-150px mt-3">
                        <li v-for="support in doctor.localSupport" :key="support" class="mb-2">
                            <u>
                                {{ support }}
                            </u>
                        </li>
                    </ul>
                </div>
            </b-col>
            <b-col md="6" lg="12" xl="6">
                <h5 class="heavy text-primary text-uppercase">
                    {{ t('surgeonDetails_Certifications') }}
                </h5>
                <ul class="list-unstyled w-150px mt-3">
                    <li
                        v-for="certification in doctor.certifications"
                        :key="certification.name"
                        class="d-flex justify-content-between"
                    >
                        <span>{{ certification.name.toUpperCase() }}</span>
                        <span class="align-self-center no-wrap text-muted">
                            {{ certification.certificationDate | date({isUTC: false}) }}</span
                        >
                    </li>
                </ul>
            </b-col>
        </b-row>
    </b-card>
</template>
<script>
import {mapState} from 'vuex';

export default {
    name: 'ComplementaryCard',
    props: {},
    data() {
        return {};
    },
    computed: {
        ...mapState({
            doctor: (state) => state.doctors.selected,
        }),
    },
};
</script>
<style lang="scss" scoped>
.max-lenses {
    width: 40px;
    padding: 8px;
    font-weight: 700;
}
.w-150px {
    width: 150px;
}
</style>

<!-- This is the Surgeon Detail page -->
<template>
    <div class="surgeon-detail d-flex flex-column">
        <b-row class="py-2">
            <b-col xl="8" class="py-2"> <PersonalCard /> </b-col>
            <b-col xl="4" class="py-2"> <ComplementaryCard /> </b-col>
        </b-row>
    </div>
</template>

<script>
import PersonalCard from './components/PersonalCard.vue';
import ComplementaryCard from './components/ComplementaryCard.vue';

export default {
    name: 'SurgeonDetails',
    components: {
        PersonalCard,
        ComplementaryCard,
    },
};
</script>
